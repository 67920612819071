
import Vue, { PropType } from "vue"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import {
  type Camera,
  CameraFeatureFlag,
  type CamerasByExid,
  CopilotChatProvider,
  CopilotLayout,
  type CopilotTimelapse,
  type CopilotTimelapsePeriod,
  type CopilotTimelapsesAvailabilityByPeriod,
  type Project,
  type ProjectsByExid,
  type User,
} from "@evercam/shared/types"
import CopilotChat from "@evercam/shared/components/copilot/CopilotChat.vue"
import CopilotTimelapseVideoSelector from "@evercam/shared/components/copilot/CopilotTimelapseVideoSelector.vue"
import CopilotTimelapseCameraSelector from "@evercam/shared/components/copilot/CopilotTimelapseCameraSelector.vue"

export default Vue.extend({
  name: "CopilotTimelapseChat",
  components: {
    CopilotTimelapseCameraSelector,
    CopilotTimelapseVideoSelector,
    CopilotChat,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    token: {
      type: String,
      default: "",
    },
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
  },
  data() {
    return {
      timelapses: {
        month: [],
        week: [],
        day: [],
        hour: [],
      } as CopilotTimelapsesAvailabilityByPeriod,
      selectedCamera: null as Camera | null,
      selectedTimelapse: null as unknown as CopilotTimelapse,
    }
  },
  computed: {
    CopilotLayout() {
      return CopilotLayout
    },
    CopilotChatProvider() {
      return CopilotChatProvider
    },
    cameras(): Camera[] {
      return this.project.cameras!.filter((c) =>
        c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
      ) as Camera[]
    },
    thumbnailUrl(): string {
      const baseUrl = this.$config.public.apiURL
      const queryParams = `authorization=${this.token}&view=true`

      return this.$imgproxy.get720pResizedImageUrl(
        `${baseUrl}/cameras/${this.selectedCamera?.exid}/thumbnail?${queryParams}`
      )
    },
    playerOptions() {
      return {
        muted: true,
        aspectRatio: "16:9",
        language: "en",
      }
    },
    playerBackgroundStyles() {
      return {
        background: `url(${this.thumbnailUrl})`,
        filter: "blur(75px)",
      }
    },
    sources() {
      return [
        {
          type: "video/mp4",
          src: this.selectedTimelapse?.url,
        },
      ]
    },
    formattedTimelapseInterval(): string {
      if (!this.selectedTimelapse) {
        return ""
      }
      const { fromDate, type } = this.selectedTimelapse
      const start = this.$moment.tz(fromDate, this.project.timezone)

      switch (type) {
        case "hour":
          return start.format(": dddd, MMM Do, YYYY, hA")
        case "day":
          return start.format(": dddd, MMM Do, YYYY")
        case "week":
          return (
            ": From " +
            start.format("MMMM Do ") +
            start.add(7, "days").format("to MMMM Do,  YYYY")
          )
        case "month":
          return ": Month of " + start.format("MMMM YYYY")
        case "year":
          return start.format(": YYYY")
        default:
          return ": Unknown timelapse type"
      }
    },
  },
  async mounted() {
    await this.selectCamera()
  },
  methods: {
    async selectCamera(camera?: Camera) {
      this.selectedTimelapse = null
      this.selectedCamera = camera || this.cameras[0]
      await this.fetchAvailableTimelapses()
    },
    convertTimezone(
      periods: CopilotTimelapsePeriod[]
    ): CopilotTimelapsePeriod[] {
      return periods.map((period) => ({
        ...period,
        timestamp: this.$moment
          .tz(period.timestamp, this.project.timezone)
          .format("YYYY-MM-DDTHH:mm:ss"),
      }))
    },
    async fetchAvailableTimelapses() {
      try {
        const timelapses =
          await EvercamLabsApi.timelapses.getAvailableTimelapses(
            this.project.exid,
            {
              cameraExid: this.selectedCamera.exid,
            }
          )

        this.timelapses = {
          month: this.convertTimezone(timelapses.month),
          week: this.convertTimezone(timelapses.week),
          day: this.convertTimezone(timelapses.day),
          hour: this.convertTimezone(timelapses.hour),
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onTimelapseSelected(id: number) {
      if (!id) {
        return
      }
      try {
        this.selectedTimelapse = await EvercamLabsApi.timelapses.getTimelapse({
          projectExid: this.project.exid,
          timelapseId: id,
        })
      } catch (e) {
        console.error(e)
      }
    },
    onVideoTimestampClicked(t) {
      const videoElement = this.$refs.player?.$el?.querySelector("video")
      if (!videoElement || !t) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_minutes, seconds] = t.split(":")
      videoElement.currentTime = Number(seconds)
    },
  },
})
